<template>
  <div id="template-list-content">
    <div class="search-container">
      <input
        type="text"
        id="template-search"
        placeholder="Search Templates..."
        v-model="searchQuery"
      >
    </div>
    <div
            id="template-selector-container"
            class="row selector-container"
    >
      <div
              v-if="filterTemplates.length > 0"
              class="template-list-list "
      >
        <div
                v-for="template in filterTemplates"
                :key="template.id"
                class="card-container"
        >
            <div class="card-body">
            <a @click="templateLoad(template)">
              <div class="template-icon">
                <iframe :srcdoc="template.previewHtml" class="template-preview"></iframe>
              </div>
            </a>
            </div>

            <div class="card-footer">
              <div class="template-name" @click="templateLoad(template)">
              <p class="card-title">
                {{ template.displayName }}
              </p>
            </div>
            <div class="template-sub-name">
              {{ new Date(template.createdAt).format() }}
            </div>

            <div class="action-icons">
              <a class="action-icon" style="margin-rigth: 5px;" @click="templateClone(template)">
                <i
                        class="fa fa-clone"
                        aria-hidden="true"
                />
              </a>
              <a class="action-icon" @click="templateDelete(template)">
                <i
                        class="fa fa-trash-o"
                        aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
              v-if="filterTemplates.length < 1 && apiGetTemplatesCalled === true"
              class="template-list-placeholder align-middle"
      >
        <div class="template-list-status-message">
          {{ string.modalEmailBuilder._notemplate }}
        </div>
        <div class="template-list-btn">
          <a
                  class="btn"
                  @click="templateCreate"
          >
            {{ string.modalEmailBuilder._create }}
          </a>
        </div>
      </div>
      <!-- BEE DOES NOT GIVE US A CONTENT PREVIEW -->
      <!--<div class="template-list-previewer col-md-6" @click="LoadTemplateBuilder" v-html="emailPreview"></div>-->
    </div>
  </div>
</template>

<script>

  import { DELETE_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATES } from '@/graphql';

  export default {
    name: 'EmailBuilderSelectTemplates',
    props: {
      lang: String,
      userId: String,
      clientSecurityCode: String,
      canCreateTemplate: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        templateList: [],
        emailPreview: '',
        string: {},
        LoadTemplateBuilder: '',
        selectedTemplate: {},
        apiGetTemplatesCalled: false,
        searchQuery: ''
      };
    },
    created () {
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      });
    },
    mounted () {
      this.getTemplates();
    },
    computed: {
      filterTemplates(){
        if(!this.searchQuery){
          return this.templateList;
        }
        return this.templateList.filter(template=>
          template.displayName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    methods : {

      templateCreate : function (template) {
        if (this.canCreateTemplate) {
          this.$emit('goCreateTemplate');
        } else {
          this.$root.bus.emit(
            "simpleToast",
            `You have reached your email template limit. Please contact to us for more or reuse your existing templates`
          );
        }
      },

      templateLoad : function (template) {
        this.$emit('templateSelected', template);
      },

      templateDelete : function (template) {
        const confirmValue = confirm(this.string.modalEmailBuilder._areyousure);

        if (confirmValue){
          this.templateList = this.templateList.filter((value) => {
            if (value.id !== template.id)  {return value;}
          });
          this.deleteTemplate(template);
        }
      },
      templateClone : function (template) {
         this.$confirm(this.string.modalEmailBuilder._areyousure_cloning)
          .then(_ => {
            this.$emit('templateCloned', template);
          })
          .catch(_ => {});
      },

      // API CALLS

      deleteTemplate : function (template){
        const context = this;
        return this.$apollo.mutate({
          mutation: DELETE_EMAIL_TEMPLATE,
          variables: {
            uniqueName: template.uniqueName
          }
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            context.$root.bus.emit('simpleToast', 'Error occurred');
            return;
          }
          if(response.data && response.data.deleteEmailTemplate) {
            context.$root.bus.emit('simpleToast', context.string.modalEmailBuilder._templateDelete);
          }
        }).catch(error => {
          console.log(error);
          context.$root.bus.emit('simpleToast', 'Error occurred');
        });
      },

      getTemplates : function (){
        const context = this;
        return this.$apollo.query({
          fetchPolicy: "no-cache",
          query: GET_EMAIL_TEMPLATES
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            context.apiGetTemplatesCalled = true;
            return;
          }
          if(response.data && response.data.getEmailTemplates) {
            context.templateList = response.data.getEmailTemplates.map(template => ({
              ...template,
              previewHtml: template.bodyHtml.replace(/\n|\r|\t/g, '')
            })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            context.apiGetTemplatesCalled = true;
          }
        }).catch(error => {
          console.log(error);
          context.apiGetTemplatesCalled = true;
        });
      },

    }
  };
</script>
<style >

  .card-container {
    overflow-wrap: break-word;
    height: 100%;
  }
  .card-body{
    /* height: 70%; */
    margin-bottom: 5px;
  }
  .card-footer {
    height: 30%;
  }
  .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .action-icons {
    text-align: right;
  }
  .action-icon {
    color: #fff;
    font-size: 20px;
  }
  .template-list-list {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding: 10px;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
    justify-content: space-around;
  }
  .action-icon:hover {
    cursor: pointer;
    color: #f7a30c;
  }

  .template-preview {
    width: 100%;
    height: 150px;
    border: none;
  }

</style>

<style lang="less" scoped>

  a {
    color: #ffffff;
    text-decoration: none;
  }

  a.btn {
    width: 100%;
    font-size: 30px;
    color: #ffffff;
    text-transform: uppercase;
    background-color: #f7a30c;
    padding: 10px;
  }

  .template-sub-name{
    font-size: 11px;
    font-style: italic;
    color: #ffffff;
  }

  .template-icon:hover{
    background-color: #b3b3b3;
    cursor: pointer;
  }

  .template-delete-icon-action{
    color: #fff;
    position: absolute;
    right: 16px;
    font-size: 20px;
    z-index: 1;
  }
  .template-clone-icon-action{
    color: #fff;
    position: absolute;
    right: 46px;
    font-size: 20px;
    z-index: 1;
  }

  #template-list-content{
    height: 100%;
    padding-top: 6px;
  }

  #template-selector-container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .align-middle{
    max-width: 50%;
    font-size: 30px;
    color: #d3d3d3;
    text-align: center;
  }

  .template-list-status-message{
    margin-bottom: 12px;
  }



  .cel{
    cursor: pointer;
    margin-bottom: 30px;
  }

  .template-icon{
    background-color: #f7a30c;
    text-align: center;
    color: white;
    // margin-bottom: 8px;
    height: 100%;
  }

  .template-name-first-letter{
    // padding-top: 140px;
    font-size: 112px;
    text-transform: uppercase;

    height: 100%;
  }

  .template-name{
    color: #ffffff;
    text-transform: capitalize;
    font-size: 18px;
    cursor: pointer; 
  }

  .cel-internal{
    height: 100%;
    width: 100%;
  }

  .search-container input {
  width: 100%;
  padding: 10px;
  font-size: 20px;
  background-color: #C0C0C0;
  color: white;
}

</style>
