<template>
  <div class="advance-settings-component-container accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.customFieldsManager._title }}
    </h4>
    <div class="form-container accordion-content">
      <small>{{ string.customFieldsManager._subtitle }}</small>
      <div class="component-menu row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="displayed_action_title">
            <transition name="component-fade-fast" mode="out-in">
              <a v-if="!showAddNew" class="btn gray-btn action" @click="showCreateNewAction(true)">
                {{ string.customFieldsManager._createNew }}
              </a>
              <div v-else class="label">
                {{ string.customFieldsManager._createNew }}
              </div>
            </transition>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="displayed_action_title">
            <transition name="component-fade-fast" mode="out-in">
              <a v-if="showAddNew" class="btn gray-btn action" @click="showCreateNewAction(false)">
                {{ string.customFieldsManager._chooseExisting }}
              </a>
              <div v-else class="label">
                {{ string.customFieldsManager._chooseExisting }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div v-if="showAddNew" class="component-form">
        <div>
          <input
            v-model.trim="newCustomFieldObj.options.question"
            aria-label=""
            type="text"
            name="policy_name"
            :placeholder="string.customFieldsManager._label"
            class="form-control"
            style="width:100%;"
          />
        </div>
        <div style="padding: 10px 0 15px; 0">
          <el-select
            v-model.trim="newCustomFieldObj.type"
            popper-class="default-popper"
            size="large"
            clearable
            :placeholder="'Select a custom field type'"
          >
            <el-option
              v-for="item in customFieldTypes"
              :key="item"
              :label="string.customFieldsManager[item]"
              :value="item"
            >
            </el-option>
            <el-option
              v-if="features.matricolaGenerator"
              key="MATRICOLA"
              :label="string.customFieldsManager['MATRICOLA']"
              value="MATRICOLA"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="newCustomFieldObj.type === 'MULTIPLE_CHOICE'" style="margin-bottom: 15px">
          <div style="row">
            <div class="col-sm-24 col-md-12 col-lg-12" style="padding:0;">
              <label class="el-form-item_label">
                {{ string.customFieldsManager._selectMax }}:
              </label>
              <NumericFieldInput
                :initial-value="1"
                :min="1"
                :max="maxOptionNumber"
                @change="onChangeMaxChoice"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-24 col-md-12 col-lg-12">
              <small>{{ string.customFieldsManager._insertAnswers }}</small>
            </div>
            <!-- <div class="col-sm-12 col-md-6 col-lg-6">
              <a class="btn import-man" :title="string.guestlist._add" @click.prevent="()=> tabValue = 'uploadSingle'">
                <i class="fa fa-plus" aria-hidden="true" />
                {{ string.guestlist._add }}
              </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <a class="btn upload" :title="string.guestlist._upload" @click.prevent="()=> tabValue = 'uploadList'">
                <i class="fa fa-upload" aria-hidden="true" />
                {{ string.guestlist._upload }}
              </a>
            </div> -->
          </div>

          <!-- Tab Content -->
          <div class="row tabs-content" style="margin: 0">
            <transition name="component-fade-fast" mode="out-in">
              <!-- <div v-if="tabValue === 'uploadList'" key="uploadList">
                <UploadCustomFieldOptions
                  :template-url="'/download/guest_template.xlsx'"
                  :on-submit="onSubmitCustomFieldOptionList"
                />
              </div> -->
              <div key="uploadSingle">
                <input
                  v-model.trim="newCustomFieldAnswer"
                  aria-label=""
                  type="text"
                  name="policy_name"
                  :placeholder="string.customFieldsManager._add_csv"
                  class="form-control"
                  style="width:100%;"
                />
                <div class="formbutton">
                  <a
                    class="gray-btn save"
                    style="width: 100%; margin: 10px 0 15px 0;"
                    :title="string.listSingleItem._save"
                    @click="onSubmitCustomFieldOption"
                  >
                    {{ string.listSingleItem._save }}
                  </a>
                </div>
              </div>
            </transition>
          </div>
          <!-- <ul v-if="haveCFieldOptions">
            <li v-for="[slug, cFieldOption] in customFieldOptionArray" :key="slug">
              {{ cFieldOption.label }}
            </li>
          </ul> -->
          <data-table
            v-show="haveCFieldOptions"
            ref="tablenew"
            :get-data="() => getData()"
            :filter-enabled="false"
            :show-pagination="false"
            :columns="answerListColumns"
          >
            <span slot="label" slot-scope="{ row }">
              {{ row.label + (row.disabled ? ` (disabled)` : ``) }}
            </span>
            <span slot="maxLimit" slot-scope="{ row }">
              <!-- {{ typeof row.limit === "number" && row.limit > 0 ? row.limit : "-" }} -->
              <NumericFieldInput
                class="p-table-cell"
                style="width:150px !important;"
                :min="row.limit"
                :initial-value="row.maxLimit"
                @change="val => (row.maxLimit = val)"
              />
            </span>
            <span slot="Actions" slot-scope="{ row }" style="text-align: center;">
              <a
                v-if="!row.disabled"
                title="delete answer"
                class="action_link"
                style="margin-right: 5px;"
                @click="beforeDeleteCustomFieldOption('tablenew', row.key)"
              >
                <i class="fa fa-trash"></i>
              </a>
            </span>
          </data-table>
        </div>
        <div class="form-section submit">
          <a type="button" class="btn gray-btn save" name="submit" @click="addCustomField">
            {{ string.customFieldsManager._create }}
          </a>
        </div>
      </div>
      <div v-if="!showAddNew" class="custom-fields-list">
        <div v-for="[slug, cField] in customFieldArray" :key="slug" class="custom-field">
          <div class="custom-field-recap">
            <div class="field custom-field-name">
              <strong> {{ string.customFieldsManager._name }}</strong>
              <strong>{{
                cField.options.question + (cField.disabled ? ` (disabled)` : ``)
              }}</strong>
            </div>
            <div class="field custom-field-edit">
              <span
                v-if="!cField.disabled"
                class="custom-field-edit-action"
                @click="archiveCustomField(slug)"
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
              </span>
              <span
                v-if="!cField.disabled && cField.type === 'MULTIPLE_CHOICE'"
                class="custom-field-edit-action"
                @click="openNewOptionDialog(slug)"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
              </span>
              <span
                v-if="!cField.disabled"
                class="custom-field-edit-action pull-right"
                @click="moveCF(slug, 'up')"
              >
                <i class="fa fa-arrow-up" aria-hidden="true"></i>
              </span>
              <span
                v-if="!cField.disabled"
                class="custom-field-edit-action pull-right"
                @click="moveCF(slug, 'down')"
              >
                <i class="fa fa-arrow-down" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div style="margin: 15px 0">
            {{ string.customFieldsManager._type }}: &nbsp; {{ cField.type.formatEnum() }}
            <span v-if="cField.type === 'MULTIPLE_CHOICE'" style="margin-left: 15px;">
              {{ string.customFieldsManager._maxSelection }}: &nbsp;
              {{ cField.options.maxSelection }}
            </span>
          </div>
          <div v-if="!cField.disabled && cField.type === TEXT_ONLY">
            <RichTextArea
              :content="cField.options.content"
              v-on:save-content="saveContent(slug, $event)"></RichTextArea>
          </div>
          <div v-if="cField.type === 'MULTIPLE_CHOICE'" class="custom-field-action">
            <data-table
              :ref="`table-${slug}`"
              :get-data="() => getData(slug)"
              :filter-enabled="false"
              :show-pagination="false"
              :columns="answerListColumns"
            >
              <span slot="label" slot-scope="{ row }">
                <input 
                  v-if="row.edit" 
                  v-model.trim="row.label" 
                  type="text" 
                  class="form-control" 
                  @change="val => (editItem.label = val.target.value)"
                />
                <div v-else>
                  {{ row.label + (row.disabled ? ` (disabled)` : ``) }}
                </div>
              </span>
              <span slot="maxLimit" slot-scope="{ row }">
                <NumericFieldInput
                  v-if="row.edit"
                  class="p-table-cell"
                  style="width:150px !important;"
                  :min="editItem.limit"
                  :initial-value="editItem.maxLimit"
                  @change="val => (editItem.maxLimit = val)"
                />
                <div v-else>
                  {{
                    typeof row.maxLimit === "number" && row.maxLimit > 0
                      ? `${row.limit || 0}/${row.maxLimit}`
                      : "-"
                  }}
                </div>
              </span>
              <span slot="Actions" slot-scope="{ row }" style="text-align: center;">
                <a
                  v-if="!row.disabled"
                  :title="row.edit ? 'save changes' : 'open editor'"
                  class="action_link"
                  style="margin-right: 5px;"
                  @click="
                    () =>
                      row.edit
                        ? saveAnswer(row, `table-${slug}`, row.key, slug)
                        : openAnswerEditor(row, `table-${slug}`, row.key, slug)
                  "
                >
                  <i :class="row.edit ? 'fa fa-check' : 'fa fa-pencil'"></i>
                </a>
                <a
                  v-if="!row.disabled"
                  :title="row.edit ? 'discard changes' : 'remove option'"
                  class="action_link"
                  style="margin-right: 5px;"
                  @click="
                    () => {
                      row.edit
                        ? closeAnswer(row, `table-${slug}`, row.key, slug)
                        : beforeDeleteCustomFieldOption(`table-${slug}`, row.key, slug);
                    }
                  "
                >
                  <i :class="row.edit ? 'fa fa-times' : 'fa fa-trash'"></i>
                </a>
                <a
                  v-if="row.disabled"
                  title="enable option"
                  class="action_link"
                  style="margin-right: 5px;"
                  @click="enableCustomField(`table-${slug}`, row.key, slug)"
                >
                  <i class="fa fa-undo"></i>
                </a>
              </span>
            </data-table>
          </div>
          <div class="custom-field-action" v-if="cField.type !== 'MATRICOLA'">
            <div class="field actions">
              <div class="single-action">
                <label class="control control-checkbox">
                  {{ string.customFieldsManager._checkInApp }}
                  <input
                    :checked="cField.checkinapp"
                    type="checkbox"
                    @change="toggleOption(slug, 'checkinapp')"
                  />
                  <div class="control_indicator"></div>
                </label>
              </div>
              <div v-if="!isPublicEvent" class="single-action">
                <label class="control control-checkbox">
                  {{ string.customFieldsManager._RSVP }}
                  <input
                    :checked="cField.rsvp"
                    type="checkbox"
                    @change="toggleOption(slug, 'rsvp')"
                  />
                  <div class="control_indicator"></div>
                </label>
                <label v-show="cField.rsvp && !cField.disabled" class="control control-checkbox">
                  {{ string.customFieldsManager._mandatory }}
                  <input
                    :checked="cField.rsvpMandatory"
                    type="checkbox"
                    @change="toggleOption(slug, 'rsvpMandatory')"
                  />
                  <div class="control_indicator"></div>
                </label>
              </div>
              <div v-if="isPublicEvent" class="single-action">
                <label class="control control-checkbox">
                  {{ string.customFieldsManager._registrationFormPage }}
                  <input
                    :checked="cField.registrationForm"
                    type="checkbox"
                    @change="toggleOption(slug, 'registrationForm')"
                  />
                  <div class="control_indicator"></div>
                </label>
                <label
                  v-show="cField.registrationForm && !cField.disabled"
                  class="control control-checkbox"
                >
                  {{ string.customFieldsManager._mandatory }}
                  <input
                    :checked="cField.registrationFormMandatory"
                    type="checkbox"
                    @change="toggleOption(slug, 'registrationFormMandatory')"
                  />
                  <div class="control_indicator"></div>
                </label>
              </div>
              <div
                class="single-action"
                v-if="user && user.role !== 'STAFF'"
                style="margin-bottom: 20px;"
              >
                <label class="control control-checkbox">
                  {{ string.customFieldsManager._hideStaff }}
                  <input
                    :checked="cField.hideStaff"
                    type="checkbox"
                    @change="toggleOption(slug, 'hideStaff')"
                  />
                  <div class="control_indicator"></div>
                </label>
              </div>
              <div class="single-action block">
                <label class="control control-checkbox">
                  {{ string.customFieldsManager._validateField }}
                  <input
                    :checked="cField.options.validateField"
                    type="checkbox"
                    @change="toggleOption(slug, 'options.validateField')"
                  />
                  <div class="control_indicator"></div>
                </label>
              </div>
              <div v-if="cField.options.validateField" class="single-action block">
                <input
                  v-model.trim="customFieldObject[slug].newValidateWithValues"
                  aria-label=""
                  type="text"
                  name="policy_name"
                  :placeholder="string.customFieldsManager._add_csv"
                  class="form-control"
                  style="width:100%;"
                />
                <div class="formbutton">
                  <a
                    class="gray-btn save"
                    style="width: 100%; height: 48px; margin: 0px;"
                    :title="string.listSingleItem._add_n_save"
                    @click="onSubmitCustomFieldOptionValidateWith(slug)"
                  >
                    {{ string.listSingleItem._add_n_save }}
                  </a>
                </div>
              </div>
              <div v-show="cField.options.validateField" class="single-action block">
                <data-table
                  :ref="'validateWithTable-' + slug"
                  :get-data="() => getValidateWithData(slug)"
                  :filter-enabled="false"
                  :show-pagination="false"
                  :columns="validateWithListColumns"
                >
                  <span slot="label" slot-scope="{ row }">
                    {{ row.label + (row.disabled ? ` (disabled)` : ``) }}
                  </span>
                  <span slot="Actions" slot-scope="{ row }" style="text-align: center;">
                    <a
                      v-if="!row.disabled"
                      title="delete answer"
                      class="action_link"
                      style="margin-right: 5px;"
                      @click="beforeDeleteCustomFieldOptionValidateWith(slug, row.key)"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </span>
                </data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        custom-class="default-popper"
        append-to-body
        :visible.sync="newOptionDialogVisible"
      >
        <span slot="title" class="dialog-title">
          {{ "Add New Answer" }}
        </span>
        <div class="filter-field">
          <input
            v-model="newOption"
            type="text"
            :placeholder="string.customFieldsManager._add_csv"
            class="form-control"
          />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeNewOptionDialog">
            Cancel
          </el-button>
          <el-button type="primary" @click="addNewOption">
            Confirm
          </el-button>
        </span>
      </el-dialog>
      <div v-if="customFieldArray.length <= 0" class="no-custom-fields">
        {{ string.customFieldsManager._noCustomFields }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  DELETE_CUSTOM_FIELD_OPTION,
  ENABLE_CUSTOM_FIELD_OPTION,
  ENUM_QUERY,
  UPDATE_EVENT
} from "@/graphql";
import UploadCustomFieldOptions from "@/containers/event/UploadCustomFieldOptions.vue";
import NumericFieldInput from "@/components/NumericFieldInput.vue";
import DataTable from "@/components/DataTable.vue";
import RichTextArea from '../../../RichTextArea.vue';
import { mapGetters } from "vuex";
import { CFPrefix } from "@/types/enums";
import { TEXT_ONLY } from '@/utils/constants';

const initialCustomField = {
  type: "OPEN_ANSWER",
  options: {
    question: ""
  }
};
export default {
  components: {
    UploadCustomFieldOptions,
    NumericFieldInput,
    DataTable,
    RichTextArea,
  },
  props: {
    eventId: String,
    event: Object,

    customFieldSource: {
      type: String,
      required: false,
      default: "GUEST"
    }
  },
  data() {
    return {
      componentName: "customFieldsManager",
      string: {},
      newCustomFieldObj: { ...initialCustomField },
      newCustomFieldAnswer: "",
      newCustomFieldAnswerOptions: {},
      showAddNew: false,
      componentDisabled: false /** Disable Component */,
      customFieldObject: {},
      customFieldTypes: ["OPEN_ANSWER", "MULTIPLE_CHOICE", "DATE", "TEXT_ONLY"],
      TEXT_ONLY,
      tabValue: "",
      answerListColumns: [
        {
          prop: "label",
          label: "Answer"
        },
        {
          prop: "maxLimit",
          label: "Limit"
        },
        {
          label: "Actions",
          width: "230"
        }
      ],
      validateWithListColumns: [
        {
          prop: "label",
          label: "Answer"
        },
        {
          label: "Actions",
          width: "230"
        }
      ],
      customFieldArray: [],
      newOptionDialogVisible: false,
      newOption: "",
      cFieldSlugForNewOption: "",
      editItem: {
        label: "",
        limit: 0,
        maxLimit: 0
      }
    };
  },
  computed: {
    ...mapGetters(["user", "features"]),
    haveCFieldOptions: function() {
      return Object.keys(this.newCustomFieldAnswerOptions).length >= 1;
    },
    customFieldOptionArray: function() {
      return this.newCustomFieldAnswerOptions
        ? Object.entries(this.newCustomFieldAnswerOptions)
        : [];
    },
    maxOptionNumber: function() {
      return Object.keys(this.newCustomFieldAnswerOptions).length;
    },
    isPublicEvent: function() {
      return this.event.genre === "PUBLIC";
    }
  },
  watch: {
    event: function() {
      this.customFieldArray = Object.entries(this.event.customFieldMapByType || {});
      this.customFieldObject = this.event.customFieldMap || {};
      for (const [key, value] of Object.entries(this.$refs)) {
        if (key.startsWith("table-")) {
          if (value && value[0] && !!value[0].getTableData) {
            value[0].getTableData();
          }
        }
      }
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    saveAnswer(row, tableRef, answerSlug, customFieldSlug) {
      if (this.editItem.label === "") {
        this.$root.bus.emit("simpleToast", "need label for question");
        return;
      }
      if (this.editItem.limit > this.editItem.maxLimit) {
        console.log({ editItem: { ...this.editItem } });
      }
      const tempAnswer = this.customFieldObject[customFieldSlug].options.answers[answerSlug];
      tempAnswer.label = this.editItem.label;
      tempAnswer.limit = this.editItem.limit;
      tempAnswer.maxLimit = this.editItem.maxLimit;
      this.customFieldObject[customFieldSlug].options.answers[answerSlug] = tempAnswer;
      this.saveCustomFieldObject();
      this.closeAnswer(row, tableRef, answerSlug, customFieldSlug);
    },
    openAnswerEditor(row, tableRef, answerSlug, customFieldSlug) {
      for (const [key, value] of Object.entries(this.$refs)) {
        if (key.startsWith("table-")) {
          if (value && value[0] && !!value[0].getTableData) {
            for (const [index, item] of value[0].tableData.entries()) {
              item.edit = false;
            }
          }
        }
      }
      this.editItem = {
        label: row.label,
        limit: typeof row.limit === "number" ? row.limit : 0,
        maxLimit: typeof row.maxLimit === "number" ? row.maxLimit : 0
      };
      row.edit = true;
    },
    closeAnswer(row, tableRef, answerSlug, customFieldSlug) {
      row.loading = false;
      row.edit = false;
      this.editItem = {
        label: "",
        limit: 0,
        maxLimit: 0
      };
    },
    openNewOptionDialog(slug) {
      this.cFieldSlugForNewOption = slug;
      this.newOptionDialogVisible = true;
    },
    saveContent(slug, content) {
      this.customFieldObject[slug].options.content = content;
      this.saveCustomFieldObject();
    },
    addNewOption() {
      const context = this;
      if (!this.newOption || this.newOption === "") {
        this.$root.bus.emit("simpleToast", "Insert an answer");
        return;
      }
      let existingOptions = this.customFieldObject[this.cFieldSlugForNewOption].options.answers;
      const parts = this.newOption.split(",");
      for (const part of parts) {
        const slugOfAnswer = this.slugify(part);
        if (
          slugOfAnswer !== "" &&
          (!existingOptions[slugOfAnswer] || typeof existingOptions[slugOfAnswer] === "undefined")
        ) {
          existingOptions = {
            ...existingOptions,
            [slugOfAnswer]: {
              key: slugOfAnswer,
              label: part.toString().trim(),
              disabled: false,
              limit: 0,
              maxLimit: 0
            }
          };
        }
      }
      this.customFieldObject[this.cFieldSlugForNewOption].options.answers = existingOptions;
      this.saveCustomFieldObject();
      this.closeNewOptionDialog();
    },
    closeNewOptionDialog() {
      this.newOptionDialogVisible = false;
      this.cFieldSlugForNewOption = "";
      this.newOption = "";
    },
    async enableCustomField(tableRef, answerSlug, customFieldSlug = "") {
      const context = this;
      return await this.$root.$apollo
        .mutate({
          mutation: ENABLE_CUSTOM_FIELD_OPTION,
          variables: {
            eventId: context.eventId,
            customFieldSlug,
            answerSlug
          }
        })
        .then(({ data, errors }) => {
          if (errors) {
            console.log("error " + errors[0]);
          } else {
            if (data.enableCustomFieldOption) {
              const message = data.enableCustomFieldOption;
              console.log(message);
              if (message === "SUCCESS") {
                context.$emit("customFieldsChanged", null);
              }
            }
          }
        })
        .catch(error => {
          console.error(error.message);
          context.$root.bus.emit(
            "simpleToast",
            context.string.customFieldsManager._error + " " + error.message
          );
        });
    },
    async beforeDeleteCustomFieldOptionValidateWith(slug, key) {
      const context = this;
      if (this.customFieldObject[slug].options.validateWith[key]) {
        return context
          .$confirm(context.string.listSingleItem._sureRemove)
          .then(async _ => {
            delete context.customFieldObject[slug].options.validateWith[key];

            if (context.$refs["validateWithTable-" + slug]) {
              if (context.$refs["validateWithTable-" + slug].length) {
                context.$refs["validateWithTable-" + slug][0].getTableData();
              } else {
                context.$refs["validateWithTable-" + slug].getTableData();
              }
            }

            context.saveCustomFieldObject();
          })
          .catch(_ => {});
      }
    },
    async beforeDeleteCustomFieldOption(
      tableRef,
      answerSlug,
      customFieldSlug = "",
      disabled = false
    ) {
      if (tableRef === "tablenew") {
        delete this.newCustomFieldAnswerOptions[answerSlug];
        if (this.$refs["tablenew"]) {
          this.$refs["tablenew"].getTableData();
        }
      } else {
        const context = this;
        if (
          this.customFieldObject[customFieldSlug] &&
          Object.keys(this.customFieldObject[customFieldSlug].options.answers).length === 1
        ) {
          return context
            .$confirm(context.string.customFieldsManager._sure)
            .then(async _ =>
              context.deleteCustomFieldOption(tableRef, answerSlug, customFieldSlug, disabled)
            )
            .catch(_ => {});
        } else {
          return this.deleteCustomFieldOption(tableRef, answerSlug, customFieldSlug, disabled);
        }
      }
    },
    async deleteCustomFieldOption(tableRef, answerSlug, customFieldSlug = "", disabled = false) {
      const context = this;
      return await this.$root.$apollo
        .mutate({
          mutation: DELETE_CUSTOM_FIELD_OPTION,
          variables: {
            eventId: context.eventId,
            customFieldSlug,
            answerSlug,
            disabled
          }
        })
        .then(({ data, errors }) => {
          if (errors) {
            console.log("error " + errors[0]);
          } else {
            if (data.deleteCustomFieldOption) {
              const message = data.deleteCustomFieldOption;
              if (message === "SUCCESS") {
                context.$emit("customFieldsChanged", null);
              } else if (message === "NOT_SAFE_TO_DELETE") {
                if (!disabled) {
                  return context
                    .$confirm(context.string.customFieldsManager._confirm_disabling_option)
                    .then(_ =>
                      context.deleteCustomFieldOption(tableRef, answerSlug, customFieldSlug, true)
                    )
                    .catch(_ => {});
                }
              }
            }
          }
        })
        .catch(error => {
          console.error(error.message);
          context.$root.bus.emit(
            "simpleToast",
            context.string.customFieldsManager._error + " " + error.message
          );
        });
    },
    slugify: function(src) {
      const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
      const to = "aaaaaeeeeeiiiiooooouuuunc------";

      const newText = src
        .split("")
        .map((letter, i) => letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i)));

      return newText
        .toString() // Cast to string
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/&/g, "-y-") // Replace & with 'and'
        .replace(/[^\w\-]+/g, "") // Remove all non-word chars
        .replace(/\-\-+/g, "-"); // Replace multiple - with single -
    },
    disableLayerClicked: function() {
      this.$root.bus.emit("notAuthorizedOptionToast"); /** Emit di al gestore globale */
    },
    onSubmit: function() {
      console.log("form is submitting");
    },
    showCreateNewAction: function(_showAddNew) {
      this.showAddNew = _showAddNew;
    },
    archiveCustomField: function(slug) {
      this.$confirm(this.string.customFieldsManager._sure)
        .then(_ => {
          delete this.customFieldObject[slug];
          this.saveCustomFieldObject();
        })
        .catch(_ => {});
    },
    isNewCustomFieldValid() {
      if (!this.newCustomFieldObj.options || !this.newCustomFieldObj.options.question) {
        this.$root.bus.emit("simpleToast", "Insert a label");
        return false;
      }
      const newSlug =
        CFPrefix[this.customFieldSource] + this.slugify(this.newCustomFieldObj.options.question);
      if (
        this.customFieldObject[newSlug] &&
        typeof this.customFieldObject[newSlug] !== "undefined"
      ) {
        this.$root.bus.emit("simpleToast", this.string.customFieldsManager._alreadyExists);
        return false;
      }
      if (this.newCustomFieldObj.type === "MULTIPLE_CHOICE") {
        if (
          !this.newCustomFieldAnswerOptions ||
          Object.keys(this.newCustomFieldAnswerOptions).length < 1
        ) {
          this.$root.bus.emit("simpleToast", "Insert answers");
          return false;
        }
      }
      return true;
    },
    addCustomField: function() {
      if (this.isNewCustomFieldValid()) {
        const newSlug =
          CFPrefix[this.customFieldSource] + this.slugify(this.newCustomFieldObj.options.question);
        this.customFieldObject[newSlug] = {
          slug: newSlug,
          label: this.newCustomFieldObj.options.question, // deprecated but still keep it for a while
          rsvp: false,
          rsvpMandatory: false,
          registrationForm: false,
          registrationFormMandatory: false,
          hideStaff: false,
          checkinapp: false, 
          disabled: false,
          type: this.newCustomFieldObj.type,
          options: { ...this.newCustomFieldObj.options }
        };
        if (this.newCustomFieldObj.type === "MULTIPLE_CHOICE") {
          const tempAnswers = {};
          // always white list the data to be saved to get rid of runtime dynamic attributes
          for (const [key, value] of Object.entries(this.newCustomFieldAnswerOptions)) {
            tempAnswers[key] = {
              key: value.key,
              label: value.label,
              disabled: typeof value.disabled === "boolean" ? value.disabled : false,
              limit: typeof value.limit === "number" && value.limit >= 0 ? value.limit : 0,
              maxLimit:
                typeof value.maxLimit === "number" && value.maxLimit >= 0 ? value.maxLimit : 0
            };
          }
          this.customFieldObject[newSlug].options = {
            ...this.customFieldObject[newSlug].options,
            answers: tempAnswers,
            maxSelection: this.customFieldObject[newSlug].options.maxSelection || 1
          };
          this.newCustomFieldAnswerOptions = {};
        }
        this.saveCustomFieldObject();
      }
    },
    toggleOption: function(slug, option) {
      if (this.customFieldObject[slug]) {
        if (option.indexOf("options.") > -1) {
          const optionWithoutPrefix = option.replace("options.", "");
          if (typeof this.customFieldObject[slug].options[optionWithoutPrefix] === "undefined") {
            this.customFieldObject[slug].options[optionWithoutPrefix] = false;
          }
          this.customFieldObject[slug].options[optionWithoutPrefix] = !this.customFieldObject[slug]
            .options[optionWithoutPrefix];
        } else {
          if (typeof this.customFieldObject[slug][option] === "undefined") {
            this.customFieldObject[slug][option] = false;
          }
          this.customFieldObject[slug][option] = !this.customFieldObject[slug][option];
        }
        this.saveCustomFieldObject();
      }
    },
    saveCustomFieldObject: function() {
      try {
        const context = this;
        return this.$root.$apollo
          .mutate({
            mutation: UPDATE_EVENT,
            variables: {
              eventId: context.eventId,
              updateEventInput: {
                customFieldMap: context.customFieldObject
              }
            }
          })
          .then(value => {
            if (value.errors) {
              console.log("error " + value.errors[0]);
            } else {
              context.newCustomFieldObj = { ...initialCustomField };
              context.newCustomFieldObj.options.question = "";
              context.showCreateNewAction(false);
              context.$emit("customFieldsChanged", null);
              context.$root.bus.emit("simpleToast", context.string.customFieldsManager._updateDone);
            }
          })
          .catch(error => {
            console.log(error.message);
            context.$root.bus.emit(
              "simpleToast",
              context.string.customFieldsManager._error + " " + error.message
            );
          });
      } catch (e) {
        console.log(e.message);
      }
    },
    onSubmitCustomFieldOptionValidateWith(slug) {
      const context = this;
      if (
        !this.customFieldObject[slug].newValidateWithValues ||
        this.customFieldObject[slug].newValidateWithValues === ""
      ) {
        // insert an answer
        this.$root.bus.emit("simpleToast", "Insert a list of validate values");
        return;
      }
      const parts = this.customFieldObject[slug].newValidateWithValues.split(",");

      if (!this.customFieldObject[slug].options.validateWith) {
        this.customFieldObject[slug].options.validateWith = {};
      }

      for (const part of parts) {
        const slugOfAnswer = this.slugify(part);

        if (
          slugOfAnswer !== "" &&
          (!this.customFieldObject[slug].options.validateWith[slugOfAnswer] ||
            typeof this.customFieldObject[slug].options.validateWith[slugOfAnswer] === "undefined")
        ) {
          this.customFieldObject[slug].options.validateWith = {
            ...this.customFieldObject[slug].options.validateWith,
            [slugOfAnswer]: {
              key: slugOfAnswer,
              label: part.toString().trim()
            }
          };
        }
      }
      this.customFieldObject[slug].newValidateWithValues = "";

      if (this.$refs["validateWithTable-" + slug]) {
        if (this.$refs["validateWithTable-" + slug].length) {
          this.$refs["validateWithTable-" + slug][0].getTableData();
        } else {
          this.$refs["validateWithTable-" + slug].getTableData();
        }
      }

      this.saveCustomFieldObject();
    },
    async onSubmitCustomFieldOptionList(file) {
      return new Promise((resolve, reject) => {
        // TODO excel parser and data collector
        resolve();
      });
    },
    onSubmitCustomFieldOption() {
      const context = this;
      if (!this.newCustomFieldAnswer || this.newCustomFieldAnswer === "") {
        // insert an answer
        this.$root.bus.emit("simpleToast", "Insert an answer");
        return;
      }
      const parts = this.newCustomFieldAnswer.split(",");
      for (const part of parts) {
        const slugOfAnswer = this.slugify(part);
        if (
          slugOfAnswer !== "" &&
          (!this.newCustomFieldAnswerOptions[slugOfAnswer] ||
            typeof this.newCustomFieldAnswerOptions[slugOfAnswer] === "undefined")
        ) {
          this.newCustomFieldAnswerOptions = {
            ...this.newCustomFieldAnswerOptions,
            [slugOfAnswer]: {
              key: slugOfAnswer,
              label: part.toString().trim(),
              disabled: false,
              limit: 0,
              maxLimit: 0
            }
          };
        }
      }
      this.newCustomFieldAnswer = "";

      if (this.$refs["tablenew"]) {
        this.$refs["tablenew"].getTableData();
      }
    },
    onChangeMaxChoice(val) {
      if (val < 1) {
        this.newCustomFieldObj.options.maxSelection = 1;
      } else if (
        this.newCustomFieldAnswerOptions &&
        val > Object.keys(this.newCustomFieldAnswerOptions).length
      ) {
        this.newCustomFieldObj.options.maxSelection = Object.keys(
          this.newCustomFieldAnswerOptions
        ).length;
      } else {
        this.newCustomFieldObj.options.maxSelection = val;
      }
    },
    async getData(slug) {
      let data = [];
      if (!slug) {
        data = Object.values(this.newCustomFieldAnswerOptions);
      } else {
        data = this.customFieldObject[slug]
          ? Object.values(this.customFieldObject[slug].options.answers)
          : [];
      }
      return Promise.resolve({
        data,
        total: data.length
      });
    },
    moveCF(slug, direction) {
      const tmpCustomFieldArray = Object.values(this.customFieldObject);
      let fromIndex = null;
      let toIndex = null;

      for (let cfindex = 0; cfindex < tmpCustomFieldArray.length; cfindex++) {
        const customField = tmpCustomFieldArray[cfindex];
        if (customField.slug === slug) {
          fromIndex = cfindex;
          break;
        }
      }

      const element = tmpCustomFieldArray[fromIndex];
      switch (direction) {
        case "up":
          if (fromIndex > 0) {
            toIndex = fromIndex - 1;
          } else {
            toIndex = tmpCustomFieldArray.length - 1;
          }
          break;
        case "down":
          if (fromIndex === tmpCustomFieldArray.length - 1) {
            toIndex = 0;
          } else {
            toIndex = fromIndex + 1;
          }
          break;
        default:
          return;
      }

      tmpCustomFieldArray.splice(fromIndex, 1);
      tmpCustomFieldArray.splice(toIndex, 0, element);

      this.customFieldObject = {};
      tmpCustomFieldArray.forEach(customField => {
        this.customFieldObject[customField.slug] = customField;
      });

      this.saveCustomFieldObject();
    },
    async getValidateWithData(slug) {
      const data = this.customFieldObject[slug].options.validateWith
        ? Object.values(this.customFieldObject[slug].options.validateWith)
        : [];

      return Promise.resolve({
        data,
        total: data.length
      });
    }
  }
};
</script>

<style scoped>
.custom-fields-list {
  width: 100%;
}
.custom-fields-list .field {
  padding: 10px 0 10px 0;
  margin: 0 0 5px 0;
}
.custom-fields-list .field.custom-field-name {
  border-right: 1px solid #ddd;
  padding-right: 40px;
}
.custom-fields-list .field.custom-field-mandatory {
  padding-left: 40px;
}
.custom-fields-list .field i {
  font-size: 20px;
}
.custom-fields-list .field i:hover {
  cursor: pointer;
  color: #f7a30c;
}
.custom-fields-list .custom-field {
  margin-bottom: 40px;
}
.custom-fields-list .custom-field-recap {
  border-bottom: 1px solid #dddddd;
}
.custom-fields-list .custom-field-name {
  display: inline-block;
  width: 29%;
}
.custom-fields-list .custom-field-edit {
  display: inline-block;
  width: 70%;
  padding-left: 15px;
}
.custom-fields-list .actions {
  display: inline-block;
  width: 100%;
  text-align: left;
  vertical-align: top;
  padding-right: 40px;
}
.custom-fields-list .text {
  display: inline-block;
  width: 59%;
}
.custom-fields-list .custom-field-pagination {
  text-align: center;
}
.custom-fields-list .custom-field-pagination .pagination li a {
  color: white !important;
}
.custom-fields-list .custom-field-result-download {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.custom-fields-list .custom-field-result-download .btn {
  text-transform: uppercase;
  color: #ffffff;
}

/*pagination color*/

.pagination > li > a,
.pagination > li > span {
  color: white !important;
}

/*checkbox*/

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
}
.control-radio .control_indicator {
  border-radius: 0;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #000;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #000;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}

/** Disable Classes */

.form-container {
  position: relative;
}

/** /Disable Classes */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #f7a30c;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
select.form-control::placeholder {
  color: #fff !important;
}
.form-control option.optionStyle {
  background: #000d;
}

.form-section button {
  margin: 0 !important;
}
.form-section.submit {
  text-align: center;
  margin-top: 20px;
}
.component-menu .btn {
  width: 100%;
  color: white;
  font-family: inherit;
  font-weight: 400;
}
.component-menu .label {
  background-color: #f7a30c;
  color: white;
  padding: 15px;
  border-width: 0px;
  text-transform: uppercase;
  margin-top: 20px;
}
.component-actions {
  margin: 20px 0 20px 0;
}
.component-menu {
  margin: 0 -15px 20px -15px;
}
.label {
  display: inline-block !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.42857143 !important;
  text-align: center !important;
  vertical-align: middle !important;
  width: 100% !important;
  border: 1px solid transparent !important;
  border-radius: 0px;
}

.single-action {
  display: flex;
}
.single-action.block {
  display: block;
  margin-bottom: 10px;
}
.single-action .control {
  flex-basis: 50%;
}
.custom-field-edit-action {
  margin-right: 10px;
}
</style>
